/* Custom styling for the react date picker */

.react-datepicker-popper {
  z-index: 20 !important;
  width: 100%;
  transform: none !important;
  top: auto !important;
  margin-top: 10px !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
  left: 50px !important;
}

.react-datepicker__time-container {
  width: 80px !important;
}
.react-datepicker__time-box {
  width: 80px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-bottom: 10px !important;
}

.react-datepicker__input-container > input {
  border-color: #0057a4 !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: rgb(240, 177, 84);
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #f90;
}

.react-datepicker__header--time {
  padding-bottom: 28px !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0px !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background-color: #f90;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: rgb(240, 177, 84);
}
.react-datepicker__day--keyboard-selected {
  background-color: #f90;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  border-width: thin;
  border-color: rgba(171, 185, 204);
  border-radius: 2px;
}

.custom-ol-style {
  counter-reset: section;
  list-style-type: none;
}

.custom-ol-style  li::before {
  counter-increment: section;
  content: counter(section) ". ";
}

.custom-ol-style  li li:before {
  content: counters(section, ".") ". ";
}

.custom-ol-style .list-alpha {
  counter-reset: alphabet-counter;
  list-style-type: none;
}

.custom-ol-style .list-alpha li:before {
  counter-increment: alphabet-counter;
  content: counter(alphabet-counter, lower-alpha) ". ";
}

.custom-ol-style .list-roman {
  counter-reset: roman-counter;
  list-style-type: none;
}

.custom-ol-style .list-roman li:before {
  counter-increment: roman-counter;
  content: counter(roman-counter, upper-roman) ". ";
}

.custom-ol-style .list-disc {
  list-style-type: disc;
}

.custom-ol-style .list-disc li:before {
  content: none;
}

.custom-ol-style.alpha-counter {
  counter-reset: section 15; /* Start from letter 'P' (ASCII code 80) */
  list-style-type: none;
}

.custom-ol-style.alpha-counter li:before {
  counter-increment: section;
  content: counter(section, lower-alpha) ". ";
}

.custom-ol-style .alpha-counter .list-roman {
  counter-reset: roman-counter;
}

.custom-ol-style .alpha-counter .list-roman li:before {
  counter-increment: roman-counter;
  content: counter(roman-counter, upper-roman) ". ";
}

.custom-ol-style .list-alpha-counter {
  counter-reset: alphabet-counter 2; /* Start from letter 'C' (ASCII code 67) */
  list-style-type: none;
}

.custom-ol-style .list-alpha-counter li:before {
  counter-increment: alphabet-counter;
  content: counter(alphabet-counter, lower-alpha) ". ";
}
